
import { defineComponent, ref, unref, toRefs, onMounted, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { getAdmin } from '@/services/api';

import { PartialAdmin } from '@/interfaces/Admin';

export default defineComponent({
  setup() {
    const adminId = useRoute().params.id as string;
    const data = ref<PartialAdmin>({});
    const roles = ref();

    const getRoles = () => {
      roles.value = data.value.roles.map(({ name }) => name);
      console.log(roles.value);
    };

    onMounted(async() => {
      const res = await getAdmin({ adminId });
      data.value = res.data;
      getRoles();
    });

    return {
      data,
      roles
    };
  }
});
